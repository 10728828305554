.program-swiper {
  margin-bottom: 20px;
}
.program-swiper .my-pagination {
  @apply h-fit absolute mb-[40px]  !important;
}

.my-pagination .swiper-pagination-bullet {
  @apply z-0 bg-white h-[10px] w-36 rounded-none md:w-20 xs:w-16 !important;
}
