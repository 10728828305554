.nav-link {
  @apply duration-300 font-calib-b border-b-4 border-b-white text-2xl text-gray-600 mt-2 px-7 pt-2 pb-1 hover:text-green md:px-0;
}
.nav-link.active {
  @apply border-b-green text-[#000];
}

.nav-border {
  @apply border-r-[1px] border-r-black md:border-r-0;
}
.square-image {
  width: 100%;
  height: 100%;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  display: flex;
  flex-direction: column;
  background-color: white;
  padding: 10px;
  z-index: 10;
  /* Add more styles as needed */
}

.dropdown-item {
  padding: 5px 0;
  /* Add more styles as needed */
}
