#square1 {
  height: 50px;
  width: 50px;
}

#square1 span {
  width: 25px;
  height: 25px;
  background-color: #7b9943;
  display: inline-block;
  -webkit-animation: square1 1.7s infinite ease-in-out both;
  animation: square1 1.7s infinite ease-in-out both;
}

#square1 span:nth-child(1) {
  left: 0px;
  -webkit-animation-delay: 0.2s;
  animation-delay: 0.2s;
}

#square1 span:nth-child(2) {
  left: 15px;
  -webkit-animation-delay: 0.3s;
  animation-delay: 0.3s;
}

#square1 span:nth-child(3) {
  left: 30px;
  -webkit-animation-delay: 0.4s;
  animation-delay: 0.4s;
}

#square1 span:nth-child(4) {
  left: 45px;
  -webkit-animation-delay: 0.5s;
  animation-delay: 0.5s;
}

@keyframes square1 {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
    transform: scale(0);
    -webkit-transform: scale(0);
    transform: scale(0);
    opacity: 0;
  }
  40% {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }
}
@-webkit-keyframes square1 {
  0%,
  80%,
  100% {
    -webkit-transform: scale(0);
    transform: scale(0);
    opacity: 0;
  }
  40% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }
}
