.home-swiper {
  margin-bottom: 20px;
}
.home-swiper .swiper-pagination {
  @apply h-fit absolute mb-[40px]  !important;
}

.swiper-pagination .swiper-pagination-bullet {
  @apply z-0 bg-white h-[5px] w-36 rounded-none md:w-20 xs:w-16 !important;
}
