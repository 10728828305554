@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "calib-r";
  src: url(../src/fonts/calibri-reguler.ttf);
}
@font-face {
  font-family: "calib-l";
  src: url(../src/fonts/calibri-light.ttf);
}
@font-face {
  font-family: "calib-b";
  src: url(../src/fonts/calibri-bold.ttf);
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  transition: all 0.2s ease-in-out;
}
html,
body {
  overflow-x: hidden;
}

.container {
  @apply max-w-7xl mx-auto lg:max-w-4xl;
}
.section {
  @apply py-10 max-w-7xl mx-auto px-5 sm:px-3;
}
.title {
  @apply text-3xl font-bold font-calib-b;
}
